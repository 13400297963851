.swiper {
	background: #eee;
	height: fit-content;
}

.swiper-wrapper {
	align-items: center;
}

.swiper-slide img{
	width: 100%;
	max-height: 600px;
	object-fit: cover;
}